import React from 'react'
import { SEO, HomeHeader as Header, Intro, Concept, Projects, Contact, Footer } from '@components'
import '@assets/css/fonts.css'
import '@assets/css/reset.css'
import '@assets/styl/default.styl'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Home = () => {
  return <>
    <SEO/>
    <Header/>
    <Intro/>
    <Concept/>
    <Projects/>
    <Contact/>
    <Footer/>
  </>
}

export default Home